import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon : 'fa fa-home',
            text : 'Home',
            link : '/'
        },
        // {
        //     icon : 'fa fa-briefcase',
        //     text : 'Our Work',
        //     link : '/our-work/'
        // },
        {
            icon : 'fa fa-rss',
            text : 'Impact Stories',
            link : '/impact-stories/'
        },
        {
            icon  : 'fa fa-users',
            text  : 'Partner With Us',
            link  : '#',
            items : [
                {
                    icon : 'fa fa-users',
                    text : 'Volunteer With Us',
                    link : '/volunteer-with-us/'
                },
                {
                    icon : 'fa fa-user-plus',
                    text : 'Work With Us',
                    link : '/work-with-us/'
                },
                {
                    icon : 'fa fa-handshake-o',
                    text : 'Partner In Project',
                    link : '/partner-with-us/'
                }
            ]
        },
        // {
        //     icon : 'fa fa-bookmark',
        //     text : 'Project',
        //     link : '/project/'
        // },
        {
            icon : 'fa fa-exclamation',
            text : 'Programs',
            link : '/programs/'
        },
        {
            icon : 'fa fa-user-secret',
            text : 'Vacancy',
            link : '/vacancy/'
        },
        {
            icon : 'fa fa-address-book-o',
            text : 'Contact Us',
            link : '/contact-us/'
        }
    ];

    const addSeparator = [
        {
            type : 'separator'
        },
        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    return [
        ...menu,
        ...addSeparator
    ];
};
export default getMenu;
